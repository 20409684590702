import React from 'react'
import NavigationBar from './NavigationBar'
import { Container } from 'react-bootstrap'
import Footer from './Footer'

export default function RefundPolicy() {
    return (
        <div>
            <NavigationBar />
            <Container style={{ marginBottom: "25rem" }}>
                <h1 style={{ marginTop: "3rem" }}>Refund Policy</h1>
                <p>
                    The Subscription Fees paid are non-refundable irrespective of whether the Service have been availed and/or consumed or not. In event of any fraudulent transaction, we may refund the Subscription Fee during the Subscription Term post successful verification and confirmation from third-party payment gateways / service providers, engaged to process and facilitate the payment of the Subscription Fee and the amount will be credited back to your account within 20 days.
                    <br /><br />   Any and all payments made under the User Agreement are non-refundable, and we do not provide refunds or credits for any partial-month subscription periods for any and all reason except if you have reason to believe that Your Account details have been obtained by another without consent. In such an event, you should immediately contact the third-party payment gateway / payment processing service provider for resolution.


                </p>
            </Container>
            <Footer />
        </div>
    )
}
