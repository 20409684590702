import React from 'react'
import NavigationBar from './NavigationBar'
import { Container } from 'react-bootstrap'
import Footer from './Footer'

export default function Contact() {
    return (
        <div>
            <NavigationBar />
            <Container>
                <div style={{ height: "80vh", display: "grid", placeItems: "center" }}>
                    <div
                        className='my-class'
                        style={{
                            border: "2px solid",
                            padding: "50px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}>
                        <h4 style={{ textAlign: "center" }}>
                            If you have any questions, concerns, or require assistance regarding our services or terms, please feel free to contact us. We are here to help!
                        </h4>
                        <h3 style={{ textAlign: "center", marginTop: "1rem" }}>Email ID <br /> <a href='mailto:tashan040988@gmail.com'>tashan040988@gmail.com</a></h3>
                    </div>

                </div>
            </Container>
            <Footer />
        </div>
    )
}
