import React from 'react'

export default function Footer() {
    return (
        <div style={{
            backgroundColor: "#0000002e",
            padding: "10px",
            verticalAlign: "middle",
            display: "flex",
            marginTop: "5rem",
            justifyContent: "center",
            alignItems: "center",
            // position: "absolute",
            // bottom: "0",
            // left: "0",
            // width: "100%",
        }}>
            <p style={{ fontWeight: "bolder" }}>
                Copyright ©️ 2024 Tashan. All rights reserved. | Powered by Light And Torch Production
            </p>
        </div>
    )
}
