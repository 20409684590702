import React from 'react'
import NavigationBar from './NavigationBar'
import aboutImg from '../assets/about.jpg'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from './Footer'


export default function Home() {
    return (
        <div>
            <NavigationBar />
            <Container style={{ marginTop: "3rem", width: "100%" }}>
                <Row>
                    <Col md="12" lg="6">
                        <img src={aboutImg} style={{ width: "100%" }} />
                    </Col>
                    <Col md="12" lg="6">
                        <h3>
                            About us
                        </h3>
                        <p>
                        Light And Torch Production is a dynamic entity in the realm of content creation. We believe in creating ideas that engages the viewers’ both emotionally and intellectually, giving them a visual treat and an entertaining experience, thereby providing tangible results. From plot ideation to story execution, incorporating key creative elements to deploying effective production strategies, we possess the knowledge essential for delivering content of the highest quality
                        </p>
                        <h3>
                            Our Mission
                        </h3>
                        <p>

                            At Tashan, our mission is simple: to provide high-quality, personalized entertainment that fits seamlessly into your life. With a vast library of content across genres, we aim to bring the world of entertainment closer to you. We believe that access to great content should be as simple as pressing a button, and we’re committed to continuously improving our service to meet the evolving needs of our viewers.
                        </p>
                        <h3>
                            Our Vision
                        </h3>
                        <p>

                            We aim to redefine the streaming experience by offering not just content, but a unique, immersive journey. We envision a platform that continues to grow, evolve, and push the boundaries of entertainment.                        </p>
                    </Col>
                </Row>

            </Container>
            <Footer />
        </div>
    )
}
